<template>
    <div 
        v-if="oldSelected.length"
        :class="wrapperClass" 
        class="old_selected">
        <div 
            v-if="showLabel" 
            class="lable mb-2 flex items-center justify-between">
            {{ $t(labelText) }}
            <!--<a-button 
                type="link"
                class="ml-2 text-current"
                size="small"
                :loading="usersLoading"
                icon="delete"
                @click="clearSavedUser()" />-->
        </div>
        <div class="flex items-center">
            <div 
                v-for="item in oldSelected" 
                :key="item.id"
                class="item_sel cursor-pointer"
                v-tippy="!isMobile ? { inertia : true, duration : '[600,300]'} : { touch: false }"
                :content="item[titleField]"
                @click="itemSelect(item)">
                <a-avatar
                    v-if="avatarFieldAsRootField"
                    :icon="avatarIcon"
                    :size="avatarSize"
                    :src="item[avatarField] || null"/>
                <a-avatar
                    v-else-if="customAvatarPath"
                    :icon="avatarIcon"
                    :size="avatarSize"
                    :src="item[avatarField] && item[avatarField][avatarSubpath] && item[avatarField][avatarSubpath][avatarPathField] ? item[avatarField][avatarSubpath][avatarPathField] : null" />
                <a-avatar
                    v-else
                    :icon="avatarIcon"
                    :size="avatarSize"
                    :src="item[avatarField] && item[avatarField][avatarPathField] ? item[avatarField][avatarPathField] : null" />
                    
                <div 
                    v-if="multiple && checkSelected(item)" 
                    class="check_user">
                    <a-icon type="check" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setData, getById, updateById, deleteDb } from './utils/userDb'
export default {
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        wrapperClass: {
            type: String,
            default: 'px-3 py-2'
        },
        checkSelected: {
            type: Function,
            default: () => {}
        },
        titleField: {
            type: String,
            default: 'full_name'
        },
        itemSelect: {
            type: Function,
            default: () => {}
        },
        getPopupContainer: {
            type: Function,
            default: () => document.body
        },
        avatarField: {
            type: String,
            default: 'avatar'
        },
        avatarPathField: {
            type: String,
            default: 'path'
        },
        databaseName: {
            type: String,
            default: 'old_select'
        },
        dbId: {
            type: String,
            default: 'user'
        },
        createdStart: {
            type: Boolean,
            default: true
        },
        labelText: {
            type: String,
            default: 'previouslySelected'
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        avatarIcon: {
            type: String,
            default: 'user'
        },
        avatarSize: {
            type: Number,
            default: 35
        },
        listMaxLenght: {
            type: Number,
            default: 9
        },
        customAvatarPath: {
            type: Boolean,
            default: false
        },
        avatarSubpath: {
            type: String,
            default: 'avatar'
        },
        avatarFieldAsRootField: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    data() {
        return {
            oldSelected: [],
            saveUsers: [],
            usersLoading: false
        }
    },
    created() {
        if(this.createdStart)
            this.getSelectedUsers()
    },
    methods: {
        async clearSavedUser() {
            try {
                this.usersLoading = true
                await deleteDb({databaseName: this.databaseName})
                this.oldSelected = []
                this.saveUsers = []
            } catch(e) {
                console.log(e)
            } finally {
                this.usersLoading = false
            }
        },
        async userSaveDb() {
            if(this.saveUsers.length) {
                try {
                    let oUsers = []
                    const data = await getById({ 
                        id: `${this.dbId}_${this.user.id}`, 
                        databaseName: this.databaseName
                    })
                    if(data?.value?.length) {
                        oUsers = data.value

                        this.saveUsers.forEach(us => {
                            const index = oUsers.findIndex(f => f.id === us.id)
                            if(index !== -1) {
                                oUsers.splice(index, 1)
                            }
                        })
                        this.saveUsers = this.saveUsers.concat(oUsers)
                    }

                    if(this.saveUsers.length > this.listMaxLenght)
                        this.saveUsers = this.saveUsers.slice(0, this.listMaxLenght)

                    if(data?.id) {
                        await updateById({
                            id: `${this.dbId}_${this.user.id}`,
                            value: this.saveUsers,
                            databaseName: this.databaseName
                        })
                    } else {
                        await setData({
                            data: {
                                id: `${this.dbId}_${this.user.id}`,
                                value: this.saveUsers
                            },
                            databaseName: this.databaseName
                        })
                    }
                    
                    this.saveUsers = []
                } catch(e) {
                    console.log(e)
                }
            }
        },
        async getSelectedUsers() {
            try {
                const data = await getById({ 
                    id: `${this.dbId}_${this.user.id}`,
                    databaseName: this.databaseName
                })
                if(data?.value?.length)
                    this.oldSelected = data.value
            } catch(e) {
                console.log(e)
            }
        },  
        saveSelect(user) {
            if(this.multiple) {
                const index = this.saveUsers.findIndex(u => u.id === user.id)
                if(index === -1) {
                    this.saveUsers.unshift(user)
                }
            } else {
                this.saveUsers.unshift(user)
            }

            this.userSaveDb()
        }
    }
}
</script>

<style lang="scss" scoped>
.old_selected{
    border-bottom: 1px solid var(--borderColor);
    .check_user{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        background: rgba(0, 0, 0, 0.2);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .lable{
        font-weight: 300;
        font-size: 13px;
    }
    .item_sel{
        position: relative;
        overflow: hidden;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        &:not(:last-child){
            margin-right: 3px;
        }
    }
}
</style>